import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { Text, PageContainer } from '@system'

const useStyles = makeStyles((theme) => ({
  headingContainer: {
    '&:after, &:before': {
      content: '""',
      height: '1px',
      flex: 1,
      backgroundColor: theme.palette.background.silver,
    },

    color: theme.palette.text.tertiary,
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '60px',
    fontFamily: 'Artifakt Element',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '1056px',
    margin: '0px auto',
    [theme.breakpoints.between('xs', 'lg')]: {
      padding: '50px 50.6px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 20px',
    },
  },
  headingText: {
    margin: '0px 30px !important',
  },
}))

const SectionHeading = (props) => {
  const classes = useStyles()
  const { headingText } = props.blok
  return (
    <SbEditable content={props.blok}>
      <PageContainer>
        <Box>
          <Text className={classes.headingContainer}>
            <span className={classes.headingText}>{headingText}</span>
          </Text>
        </Box>
      </PageContainer>
    </SbEditable>
  )
}

export default SectionHeading
